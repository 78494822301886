

$(document).ready(function () {
    "use strict";


    /*==Main Slider==*/


    (function ($) {
        var MainSilderCarousel = $('.jb-home-slider');
        if (MainSilderCarousel.length > 0) {
            MainSilderCarousel.owlCarousel({
                merge: true,
                loop: false,
                nav: false,
                center: false,
                dots: true,
                animateIn: 'fadeIn',
                autoplay: false,
                autoplayTimeout: 5000,
                margin: 0,
                items: 1,
            });
        }
    })(jQuery);


    /*==Premises Slider==*/


    (function ($) {
        var showcaseCarousel = $('.jb-premises-slider');
        if (showcaseCarousel.length > 0) {
            showcaseCarousel.owlCarousel({
                center: true,
                items: 5,
                dots: true,
                nav: false,
                loop: true,
                margin: 0,
                responsive: {
                    0: {
                        items: 2
                    },
                    600: {
                        items: 3
                    },
                    1000: {
                        items: 3
                    }
                }
            });
        }
    })(jQuery);


    /*==Review Slider==*/


    (function ($) {
        var testimonialCarousel = $('.jb-testimonial-slider');
        if (testimonialCarousel.length > 0) {
            testimonialCarousel.owlCarousel({
                margin: 16,
                loop: true,
                nav: false,
                center: false,
                dots: true,
                autoHeight : true,
                autoplay: true,
                autoplayTimeout: 15000,
                items: 1,
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: false,
                        dots: true
                    },
                    600: {
                        items: 1,
                        nav: false,
                        dots: true
                    },
                    1000: {
                        items: 1,
                        nav: false,
                        dots: true
                    }
                }
            });
        }
    })(jQuery);


    /*==Accordian JS==*/


    (function ($) {
        $(".jb-question-box").click(function () {
            if ($(this).hasClass('active')) {
                $('.jb-question-box').removeClass('active');
                $(this).removeClass('active')
            } else {
                $('.jb-question-box').removeClass('active');
                $(this).addClass('active')
            }
        });
    })(jQuery);


    /*==Mobile Footer Toggle==*/


    (function ($) {
        var contentwidth = jQuery(window).width();
        if ((contentwidth) < '575') {
            jQuery('.jb-footer-title').append('<i class="icofont icofont-circled-down"></i>');
            jQuery('.jb-footer-title').click(function () {
                jQuery('.jb-footer-title').removeClass('active');
                jQuery('.jb-footer-menu').slideUp('normal');
                if (jQuery(this).next().is(':hidden') == true) {
                    jQuery(this).addClass('active');
                    jQuery(this).next().slideDown('normal');
                }
            });
            jQuery('.footer-content').hide();
        } else {
            jQuery('.footer-content').show();
        }
    })(jQuery);

});


/*==WOW Animation==*/


(function ($) {
    var length = $('.wow').length;
    if (length >= 1) {
        wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
        });
        wow.init();
    }
})(jQuery);


/*==Onscroll JS==*/


$(window).on("scroll", function () {
    "use strict";
    var sections = $('section'),
        nav = $('.navbar-nav'),
        nav_height = nav.outerHeight() + 25;
    $(window).scrollTop() >= 20 ? $("nav").addClass("sticky-header") : $(".sticky").removeClass("sticky-header");


    /*==On Scroll Change Active Menu==*/


    var cur_pos = $(this).scrollTop();
    var contentwidth = jQuery(window).width();
    if ((contentwidth) > '991') {
        sections.each(function () {
            var top = $(this).offset().top - nav_height,
                bottom = top + $(this).outerHeight();
            if (cur_pos >= top && cur_pos <= bottom) {
                nav.find('li').removeClass('active');
                $(this).addClass('active');
                nav.find('a[href="#' + $(this).attr('id') + '"]').parent().addClass('active');
            }
        });
    }
}),
    $(".nav-item a").on("click", function (o) {
        var t = $(this);
        $('.nav-item').removeClass('active');
        $(t).parent().addClass('active');
        $("html, body").stop().animate({
            scrollTop: $(t.attr("href")).offset().top - 50
        }, 1500, "easeInOutExpo"), o.preventDefault()
    }),
    $(document).on("click", ".navbar-collapse.show", function (o) {
        $(o.target).is("a") && $(this).collapse("hide")
    }),
    $(window).on("scroll", function () {
        $(this).scrollTop() > 100 ? $(".jb-back_top").fadeIn() : $(".jb-back_top").fadeOut()
    }),
    $(".jb-back_top").on("click", function () {
        return $("html, body").animate({
            scrollTop: 0
        }, 1e3), !1
    });


/*==Other Link JS==*/


(function ($) {
    "use strict";
    $(".jb-home-slider .item a, .jb-contact_btn a").on("click", function (event) {
        var t = $(this);
        $("html, body").stop().animate({
            scrollTop: $(t.attr("href")).offset().top - 50
        }, 2000, "easeInOutExpo"), event.preventDefault()
    })
})(jQuery);


// Initate the hero bottom waves


if ($('#jb-wave1').length && $('#jb-wave2').length) {
    wavify(document.querySelector('#jb-wave1'), {
        height: 40,
        bones: 4,
        amplitude: 40,
        color: '#fff',
        speed: .15
    });

    wavify(document.querySelector('#jb-wave2'), {
        height: 20,
        bones: 3,
        amplitude: 40,
        color: 'rgba(255, 255, 255, .1)',
        speed: .25
    });
}

//validation of appointment 
var fullNameError = document.getElementById('fullName-error');
var dropDownError = document.getElementById('dropDown-error');
// var lastnameError = document.getElementById('lastName-error');
var emailError = document.getElementById('email-error');
var phoneError = document.getElementById('phone-error');
var messageError = document.getElementById('message-error');
var btn = document.getElementsByClassName('jb-book-now-btn');

// let emailConfigs = {
//     Host: "smtp.elasticemail.com",
//     Username: "vishal.ks.190296@gmail.com",
//     Password: "0B3A3D1CB65CB44F18E1D75F7D50DCFABEA6",
//     To: 'vishal.ks.190296@gmail.com',
//     From: "vishal.ks.190296@gmail.com",
//     Subject: "New client Enquiry",
// }

function validateFullName() {
    var fullName = document.getElementById('fullName').value;

    if (fullName.length == 0) {
        fullNameError.innerHTML = "Full Name is required";
        return false;
    }
    if (!fullName.match(/^[A-Za-z][A-Za-z\s]*$/)) {
        fullNameError.innerHTML = "Please use alphabates only";
        return false;
    }

    if (fullName.length < 3) {
        fullNameError.innerHTML = "Please write full name";
        return false;
    }

    fullNameError.innerHTML = '<i class="icofont icofont-check-circled"></i>';
    return true;
}

// function validateDropdown() {
//     var dropDown = document.getElementById('dropDown').value;
//     if (dropDown == "") {
//         dropDownError.innerHTML = "Please select one of these";
//         return false;
//     }

//     dropDownError.innerHTML = '<i class="icofont icofont-check-circled"></i>';
//     return true;
// }

// function validateLastName() {
//     var lName = document.getElementById('lName').value;

//     if (lName.length == 0) {
//         lastnameError.innerHTML = "Last Name is required";
//         return false;
//     }

//     if (!lName.match(/^[A-Za-z]+$/)){
//         lastnameError.innerHTML = "Please use alphabates only";
//         return false;
//     }

//     if (lName.length < 3) {
//         lastnameError.innerHTML = "Please write full last name";
//         return false;
//     }

//     lastnameError.innerHTML = '<i class="icofont icofont-check-circled"></i>';
//     return true;
// }

function validateEmail() {
    var email = document.getElementById('id_email').value;

    if (email.length == 0) {
        emailError.innerHTML = 'Email number is required';
        return false;
    }
    if (!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        emailError.innerHTML = 'Email Invalid';
        return false;
    }

    emailError.innerHTML = '<i class="icofont icofont-check-circled"></i>';
    return true;
}

function validatePhone() {
    var phone = document.getElementById('id_phone_number').value;

    // if (phone.length == 0) {
    //     phoneError.innerHTML = 'Phone number is required';
    //     return false;
    // }
    if (phone.length !== 10) {
        phoneError.innerHTML = 'Phone number must be 10 digit';
        return false;
    }
    if (!phone.match(/^[0-9]{10}$/)) {
        phoneError.innerHTML = 'Only digits please';
        return false;
    }
    phoneError.innerHTML = '<i class="icofont icofont-check-circled"></i>';
    return true;
}

// function validateMessage() {
//     var message = document.getElementById('message').value;
//     var required = 30;
//     var left = required - message.length;

//     if (left > 0) {
//         messageError.innerHTML = left + ' more characters required';
//         return false;
//     }

//     messageError.innerHTML = '<i class="icofont icofont-check-circled"></i>';
//     return true;
// }

let isFormValid = () => {
    return validateFullName() && validateEmail();
    
}
function check(){
    if(isFormValid()){
        fullNameError.innerHTML = "";
        dropDownError.innerHTML = "";
        // lastnameError.innerHTML = "";
        emailError.innerHTML = "";
        phoneError.innerHTML = "";
        messageError.innerHTML = " ";
    }
}

// let sendEmail = () => {
//     emailConfigs.Body = "Name: " + document.getElementById('fName').value + ' ' + document.getElementById('lName').value + '<br> Email: ' + document.getElementById('id_email').value + '<br> Phone number: ' + document.getElementById('id_phone_number').value + '<br> Message: ' + document.getElementById('message').value
//     console.log(emailConfigs);
//     Email
//         .send(emailConfigs)
//         .then(message => alert(message));
// } 

// function submitForm(_event) {
//     _event.preventDefault();
//     if (isFormValid()) {
//         sendEmail();
//     } 
// }









// Add active class to the current button (highlight it)
// var header = document.getElementsByClassName("jb-testimonial-slider");
// var btns = header.getElementsByClassName("tes-btn");
// for (var i = 0; i < btns.length; i++) {
//   btns[i].addEventListener("click", function() {
//   var current = document.getElementsByClassName("tes-none");
//   current[0].className = current[0].className.replace(" active", "");
//   this.className += " active";
//   });
// }









let toggleShowLessInfo = (_index) => {
    let tCards = document.querySelectorAll(`#jb-testimonial-card-${_index}`);
    let tParas = document.querySelectorAll(`#tes-para-${_index}`);
    let tButtons = document.querySelectorAll(`#tes-btn-${_index}`);
    if (tButtons[0].innerText === 'READ MORE') {
        tCards.forEach(_ele => _ele.style.height = "auto");
        tParas.forEach(_ele => _ele.style.height = "auto");
        tCards.forEach(_ele => _ele.style.animation = "slideDownCard 1s ease");
        tParas.forEach(_ele => _ele.style.animation = "slideDownPara 1s ease");
        tButtons.forEach(_ele => _ele.innerText = "READ LESS");
    } else {
        tCards.forEach(_ele => _ele.style.height = "125px");
        tParas.forEach(_ele => _ele.style.height = "75px");
        tCards.forEach(_ele => _ele.style.animation = "slideUpCard 1s ease");
        tParas.forEach(_ele => _ele.style.animation = "slideUpPara 1s ease");
        tButtons.forEach(_ele => _ele.innerText = "READ MORE");
    }
}


// MailChimp subscribe msg change :
var target = document.getElementById('mce-success-response');
var observer = new MutationObserver(function(mutations) {
  mutations.forEach(function(mutation) {
    if (target.innerHTML === "Thank you for subscribing!") {
      target.innerHTML = "Thank you for reaching out and we’ll revert at the earliest.";
    }
  });
});
var config = { attributes: true, childList: true, characterData: true };
observer.observe(target, config);

